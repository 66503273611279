.piedetails-main {
    width: 100%;
    background-color: #F0F0E5;
    /*background-color: aqua;*/
    position: absolute;
    z-index: 1;
}

.pie-image {
    height: 50vw;
    width: 40vw;
    border: 1px solid black;
    object-fit: cover;
}

.pie-image-mobile {
    width: 100vw;
    object-fit: cover;
}

.button-div {
    position: sticky;
    top: 5px;
    left: 5px;
    justify-content: left;
    width: 15vw;
    min-width: 170px;
    margin-top: 10px;
}

.mobile-button-div {
    position: sticky;
    top: 0;
    width: 100vw;
}

#back-button {
  align-items: center;
  background-color: #6D342C;
  border-radius: 4px;
  box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter,sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s,-webkit-box-shadow .2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

#back-button:hover {
  box-shadow: #121212
}

.pillbox {
    padding-top: 10px;
    border: 1px black solid;

}

.textbox {
    border: 1px black solid;
    width: 60vw;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 15px;
    justify-content: left;
    text-align: left;
    padding: 15px;
}

.textbox-mobile {
    border: 1px black solid;
    width: 100vw;
    margin: 0 auto;
    margin-top: -10px;
    background-color: white;
    border-radius: 5px;
    justify-content: left;
    text-align: left;
    padding: 15px;
}