.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile-image {
  width: 100vw;
  object-fit: cover;
}

.mobile-textbox {
  width: 90vw;
  margin: 0 auto;
}

.mobile-button {
  width: 90vw;
  height: 10vw;
  background-color: #6D342C;
  color: white;
  font-weight: bolder;
  border-radius: 5px;
  margin-bottom: 20px;
}

.mobile-hero {
  padding-bottom: 40px;
}

.icebreaker-button {
  height: 5vw;
  background-color: #6D342C;
  color: white;
  font-weight: bolder;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 40vw;
}

.icebreaker-content {
  width: 50vw;
  margin: 0 auto;
  text-align: left;
}

.icebreaker-content-mobile {
  width: 90vw;
  margin: 0 auto;
}

.pie-header {
  margin: -10px 0px 10px 0px;
  border: 1px solid black;
  color: white;
  background-color: #365681;
  
}

.icebreaker-div {
  background-color: #D0D6B5;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0 auto;
  width: 100vw;
  margin-bottom: -10px;
  margin-top: -10px;
}

.hero {
  margin-bottom: 100px;
}
